import React from "react";
import { Modal, Button } from "react-bootstrap";
import Header from "../Parts/header";
import Payment from "./payment";

class Donation extends React.Component {
  state = {
    isOpen: false,
    validation: false,
    donationAmount: "",
    minimumAmount: 0.01,
    donationReceived: false,
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };
  handleShow = () => {
    this.setState({ isOpen: true });
  };

  handleChange = ({ target: input }) => {
    this.setState({ donationAmount: input.value });
  };

  showValidation = () => {
    this.setState({ validation: true });
  };

  handlePayment = (paid) => {
    this.setState({ donationReceived: paid });
  };

  render() {
    return (
      <>
        <div className="donate-wrapper">
          <button
            type="button"
            className="btn donate-btn"
            onClick={this.handleShow}
          >
            Donate &nbsp;
            <i className="fa fa-heart" aria-hidden="true"></i>
          </button>
        </div>

        {this.state.donationReceived && (
          <div className="on-payment-success">
            <p className="payment-success text-upper">Thank You!</p>
            <div className="success-icon-wrapper mx-auto">
              <i className="fa fa-check success-icon" aria-hidden="true"></i>
            </div>
          </div>
        )}

        <Modal show={this.state.isOpen} onHide={this.handleClose} centered>
          <Modal.Body>
            <p className="sub-heading text-center">Donate to</p>
            <div className="donate-contents">
              <Header />
              <div className="input-group mb-3 w-50 mx-auto">
                <span className="input-group-text">$</span>
                <input
                  type="number"
                  name="donation-amount"
                  id="donation-amount"
                  value={this.state.donationAmount}
                  onChange={this.handleChange}
                  className="form-control"
                  aria-label="Dollar amount (with dot and two decimal places)"
                  placeholder="0.00"
                  onFocus={this.showValidation}
                />
              </div>
              <div className="text-center">
                {this.state.validation &&
                  parseFloat(this.state.donationAmount) <
                    this.state.minimumAmount && (
                    <small class="form-text text-danger">{`Minimum Donation: $ ${this.state.minimumAmount} `}</small>
                  )}
              </div>
              <div
                className={
                  parseFloat(this.state.donationAmount) >=
                  this.state.minimumAmount
                    ? "p-5"
                    : "paypal-payment-disable p-5"
                }
              >
                <Payment
                  amount={this.state.donationAmount}
                  onPayment={this.handlePayment}
                />
              </div>
              {this.state.donationReceived && (
                <div className="on-payment-success">
                  <p className="payment-success text-success">
                    Transaction Sucessful &nbsp;
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </p>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Donation;
