import React, { Component } from "react";

class BackToTop extends Component {
  state = {
    showBackToTop: false,
  };

  handleWindowScroll = () => {
    if (document.documentElement.scrollTop > 200) {
      this.setState({ showBackToTop: true });
    } else {
      this.setState({ showBackToTop: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleWindowScroll);
  }

  handleClick = () => {
    document.documentElement.scrollTop = 0;
  };

  render() {
    return (
      this.state.showBackToTop && (
        <button
          type="button"
          className="btn btn-floating rounded-circle"
          id="btn-back-to-top"
          onClick={this.handleClick}
        >
          <i class="fa fa-angle-up text-white" aria-hidden="true"></i>
        </button>
      )
    );
  }
}

export default BackToTop;
