import React from "react";
import Carousel from "react-bootstrap/Carousel";
import http from "../Services/httpService";

class ImageSlider extends React.Component {
  state = {
    images: [],
  };

  async componentDidMount() {
    const { data: images } = await http.get(
      process.env.REACT_APP_API_URL + "/api/image-slider"
    );

    if (images) {
      this.setState({ images });
    }
  }

  render() {
    return (
      <Carousel variant="dark" indicators={false} interval={4000}>
        {this.state.images.map((image, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100 fixed-height"
                src={image}
                alt="..."
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
}

export default ImageSlider;
