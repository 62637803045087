import React from "react";

class Services extends React.Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="main-content-wrapper">
          <p className="headings">Services</p>
          <hr className="heading-seperator" />
          <div className="row row-cols-1 row-cols-xl-2 cell">
            <div className="col mb-3">
              <img src={"https://sysenso-storage.s3.eu-west-1.amazonaws.com/images/webpage/AdobeStock_59232064.jpeg"} className="rounded fixed-width" alt="..." />
            </div>
            <div className="col service-contents pt-2 pt-xl-0">
              <p className="sub-heading">Projects</p>
              <ul className="list-items-wrapper">
                <li className="list-item">
                  Transforming the embedded controller C-code into
                  Simulink/Stateflow models.
                </li>
                <li className="list-item">
                  EE HIL(dSPACE) Validation of Body Control Module features.
                </li>
                <li className="list-item">
                  Python based MIL/HIL test execution tools development.
                </li>
                <li className="list-item">
                  Validation the Diagnostics and Network Management of ECUs.
                </li>
                <li className="list-item">
                  Animation of material handling system with connected with
                  closed loop model.
                </li>
                <li className="list-item">
                  MIL and SIL validation of control models of transmission
                  features.
                </li>
                <li className="list-item">
                  Code generation customization for Simulink models.
                </li>
                <li className="list-item">
                  Standalone simulation (creating executable) for plant and
                  controller models.
                </li>
                <li className="list-item">Process documentation.</li>
                <li className="list-item">
                  Test vector generation for Simulink and C-code modules.
                </li>
                <li className="list-item">
                  Custom Simulink block(S-function) development.
                </li>
                <li className="list-item">
                  MATLAB GUI development using GUIDE, uicommands. App Designer
                </li>
                <li className="list-item">MATLAB based tools development</li>
              </ul>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-xl-2 cell">
            <div className="col service-contents">
              <p className="sub-heading">Tools</p>
              <p className="contents">Mathworks tool chain</p>
              <ul className="list-items-wrapper">
                <li className="list-item">MATLAB</li>
                <li className="list-item">Simulink</li>
                <li className="list-item">Stateflow</li>
                <li className="list-item">
                  Simulink Coder(RTW &amp; Stateflow Coder)
                </li>
                <li className="list-item">Embedded Coder</li>
                <li className="list-item">MATLAB-Compiler</li>
                <li className="list-item">Simulink 3D Animation</li>
                <li className="list-item">
                  Simulink Verification and Validation
                </li>
              </ul>
              <p className="contents">dSPACE tool chain</p>
              <ul className="list-items-wrapper">
                <li className="list-item">dSPACE RTI blocks</li>
                <li className="list-item">ControlDesk</li>
                <li className="list-item">Python automation libraries</li>
              </ul>
              <p className="contents">Scilab/Xcos Package</p>
              <p className="contents">Octave Modules</p>
              <p className="contents">Programming Languages</p>
              <ul className="list-items-wrapper">
                <li className="list-item">C/C++</li>
                <li className="list-item">Java</li>
                <li className="list-item">
                  Python(NumPy, SciPy, Scikit, etc..)
                </li>
              </ul>
            </div>
            <div className="col order-first order-xl-last mb-3">
              <img src={"https://sysenso-storage.s3.eu-west-1.amazonaws.com/images/webpage/AdobeStock_403143221.jpeg"} className="rounded fixed-width" alt="..." />
            </div>
          </div>
          <div className="row row-cols-1 row-cols-xl-2 cell">
            <div className="col mb-3">
              <img src={"https://sysenso-storage.s3.eu-west-1.amazonaws.com/images/webpage/AdobeStock_85646898.jpeg"} className="rounded fixed-width" alt="..." />
            </div>
            <div className="col service-contents">
              <p className="sub-heading">Featured Services</p>
              <ul className="list-items-wrapper">
                <li className="list-item">
                  MATLAB/Simulink based tools and process development
                </li>
                <li className="list-item">
                  Event-driven controller modeling and data based plant modeling
                </li>
                <li className="list-item">
                  Tools usability feedback and tools automation
                </li>
                <li className="list-item">
                  Process expertise with MIL/SIL/HIL
                </li>
                <li className="list-item">
                  New tools initiatives and innovation
                </li>
                <li className="list-item">Python automation</li>
                <li className="list-item">
                  Optimization techniques and Numerical Methods
                </li>
                <li className="list-item">Software testing methodlogies</li>
                <li className="list-item">Animation with Simulink models</li>
                <li className="list-item">
                  C-code to Simulink and Stateflow Conversion
                </li>
                <li className="list-item">
                  C-code generation from Simulink/Stateflow models
                </li>
                <li className="list-item">
                  dSPACE automated HIL testing with Python - Functional,
                  diagnostics, Network Management etc..
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="contact-seperator" />
      </div>
    );
  }
}

export default Services;
