import React from "react";
import Contact from "../Parts/contact";

class AboutUs extends React.Component {
  state = {};
  render() {
    return (
      <>
        <div className="container">
          <div className="main-content-wrapper">
            <p className="headings">About Us</p>
            <hr className="heading-seperator" />
            <div className="row row-cols-1 row-cols-xl-2">
              <div className="col col-xl-5 text-center">
                <img
                  src={"https://sysenso-storage.s3.eu-west-1.amazonaws.com/images/webpage/AdobeStock_143233821.jpeg"}
                  className="rounded img-fluid thumb-img"
                  alt="..."
                />
              </div>
              <div className="col col-xl-7 order-xl-first about-us-contents">
                <div className="cell">
                  <p className="sub-heading">Company Profile</p>
                  <p className="contents">
                    Sysenso was founded in Nov 2017 with the focus on Systems
                    Engineering Tools and Processes Development.
                  </p>
                </div>
                <div className="cell">
                  <p className="sub-heading">Team</p>
                  <p className="contents">
                    We are a team with a mix of young talents and experienced
                    professionals. The team is smart and willing to go
                    extra-mile in every assignments. We involve ourselves to
                    give complete solutions and make our customers delighted.
                  </p>
                </div>
                <div className="cell">
                  <p className="sub-heading">Objectives</p>
                  <p className="contents">
                    To be a established multi-disciplinary engineering and
                    technology company to develop innovative products, processes
                    and deliver services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Contact />
      </>
    );
  }
}

export default AboutUs;
