import React from "react";
import ImageSlider from "../Components/imageSlider";
import Contact from "../Parts/contact";

class Home extends React.Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="main-content-wrapper">
            {/* Core Skills */}
            <div className="core-skills-wrapper">
              <p className="headings">Core Skills</p>
              <hr className="heading-seperator" />
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-center core-skills-contents gy-4 gy-lg-0">
                <div className="col text-center">
                  <p className="sub-heading">Modeling &amp; Simulation</p>
                  <p className="contents">
                    MATLAB/Simulink based modeling and simulation activities
                  </p>
                </div>
                <div className="col text-center">
                  <p className="sub-heading">Technical Consulting</p>
                  <p className="contents">
                    Processes development and automation, Optimization,
                    Verification and Validation, Machine Learning, IoT, Embedded
                    Systems
                  </p>
                </div>
                <div className="col text-center">
                  <p className="sub-heading">
                    Tools Development &amp; Programming
                  </p>
                  <p className="contents">
                    MATLAB/Simulink, Octave, Scilab/Xcos,
                    Python(NumPy/SciPy/Scikit/PyQT/...)
                  </p>
                </div>
              </div>
            </div>
            {/* Products and Services */}
            <div className="products-services-wrapper">
              <p className="headings">PRODUCTS AND SERVICES</p>
              <hr className="heading-seperator" />
              <div className="row row-cols-1">
                <div className="col text-center mb-4 home-img"></div>
                <div className="col products-service-contents pt-2 pt-xl-0">
                  <div className="cell">
                    <p className="sub-heading">Systems Engineering Tools</p>
                    <p className="contents">
                      Experienced in Systems Engineering(Interdisciplinary field
                      of engineering) tools helps to design and manage complex
                      systems over their life cycles. The tools are Model-Based
                      Diagrams, Requirements Management, Traceability, etc..
                      Some of products are,
                    </p>
                    <ul className="list-items-wrapper">
                      <li className="list-item">
                        Coverage and Test Vector Generation tool for Simulink
                        model
                      </li>
                      <li className="list-item">
                        CompareModels - Customized model comparison tool
                      </li>
                      <li className="list-item">
                        SimBreakpoints - Simulink based model debugging tool
                      </li>
                      <li className="list-item">
                        FindReplaceTool - Find and replace tool for SImulink
                        model
                      </li>
                      <li className="list-item">
                        ModelAlignmentTool - Automatic model alignment tool for
                        Simulink and Xcos models
                      </li>
                    </ul>
                  </div>
                  <div className="cell">
                    <p className="sub-heading">Engineering Services</p>
                    <p className="contents">
                      We have consulting experience with Model Based Systems
                      Engineering(MBSE) projects such as MIL/SIL/HIL
                      validations, Test Automation, Embedded Code Generation,
                      etc..
                    </p>
                    <p className="contents">
                      We have good expertise in MATLAB/Simulink, Scilab/Xcos,
                      Octave, Python etc.. based tools and processes
                      development.
                    </p>
                  </div>
                  <div className="cell">
                    <p className="sub-heading">Training</p>
                    <p className="contents">
                      We offer training in the following topics,
                    </p>
                    <ul className="list-items-wrapper">
                      <li className="list-item">
                        Software Training - MATLAB/Simulink, Scilab/Xcos,
                        Octave, Python
                      </li>
                      <li className="list-item">
                        Process Training - Simulink Model Based System
                        Development, Verification and Validation, GUI
                        Development in Python
                      </li>
                    </ul>
                  </div>
                  <div className="cell">
                    <p className="sub-heading">Process Solutions</p>
                    <p className="contents">
                      We have also some semi-automated processes and solutions
                      built to specific projects.
                    </p>
                    <ul className="list-items-wrapper">
                      <li className="list-item">
                        C-code to Simulink model conversion projects
                      </li>
                      <li className="list-item">
                        HIL Test Automation - Excel based test cases to generate
                        Python test automation with dSPACE
                      </li>
                      <li className="list-item">
                        Advanced GUI development for Engineering Software
                        Products with MATLAB and Scilab.
                      </li>
                    </ul>
                  </div>
                  <div className="cell">
                    <p className="sub-heading">Micro Projects</p>
                    <p className="contents">
                      We have worked a wide range of projects like Optimization,
                      Curve Fitting, GUI Development, Speed Improvements of
                      Algorithms, Debugging Tools, Software Conversions. etc..
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Galllery */}
            <div className="image-gallery-wrapper">
              <p className="headings">Image Gallery</p>
              <hr className="heading-seperator" />
              <div className="image-gallery">
                <ImageSlider />
              </div>
            </div>
          </div>
        </div>
        <Contact />
      </>
    );
  }
}

export default Home;
