import http from "./httpService";

const blogApiURL = process.env.REACT_APP_API_URL + "/api/blogs";

async function getBlogList() {
  return http.get(blogApiURL);
}

function getBlogs(blogId) {
  return http.get(`${blogApiURL}/${blogId}`);
}

export { getBlogList, getBlogs };
