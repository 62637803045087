import React from "react";
import { Events, Link } from "react-scroll";
import { Accordion, Collapse, Alert, ListGroup } from "react-bootstrap";
import parse from "html-react-parser";
import { getCommercialProducts } from "../Services/commercialProductService";
import Joi from "joi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactMarkdown from "react-markdown";
import { addUser } from "../Services/userService";
import { contactBuyProduct } from "../Services/emailService";
import { TailSpin } from "react-loader-spinner";
import BackToTop from "../Parts/backToTop";

class CommercialProducts extends React.Component {
  state = {
    products: [],
    collapseOpen: [],
    activeProduct: 0,
    sendingMail: false,
    account: {
      name: "",
      email: "",
      purpose: "",
    },
    error: "",
  };

  async componentDidMount() {
    const { data: products } = await getCommercialProducts();

    this.setState({ products });

    Events.scrollEvent.register("begin", function () {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      // console.log("end", arguments);
    });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  schema = Joi.object({
    name: Joi.string().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    purpose: Joi.string().required().label("Purpose"),
  });

  // Validate on Submit
  validate = () => {
    const result = this.schema.validate(this.state.account);

    if (!result.error) return null;

    const errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  handleChange = ({ target: input }) => {
    const account = { ...this.state.account };
    account[input.name] = input.value;
    this.setState({ account });
  };

  contactUs = async (productId) => {
    const productName = this.state.products.find(
      (product) => product._id === productId
    ).title;

    this.setState({ sendingMail: true });

    const { data: result } = await contactBuyProduct({
      productType: "Commercial",
      productId: productId,
      productName: productName,
      ...this.state.account,
    });

    if ("MessageId" in result || "ResponseMetadata" in result) {
      this.setState({ sendingMail: false });
      try {
        toast.success(`We Will Contact You Soon`);
      } catch (error) {
        toast.error("Email Not Sent.Try Again");
      }
    }
  };

  setCollapse = (productIndex) => {
    const { collapseOpen } = this.state;
    const index = collapseOpen.indexOf(productIndex);

    if (index === -1) {
      collapseOpen.push(productIndex);
      this.setState({ collapseOpen });
    } else {
      const error = this.validate();
      if (error) {
        const key = Object.keys(error)[0];
        this.setState({ error: error[key] });
      } else {
        this.setState({ error: "" });
        this.contactUs(productIndex);
      }
    }
  };

  addActiveProductClass = (productIndex) => {
    this.setState({ activeProduct: productIndex });
  };

  render() {
    const { products, account } = this.state;
    return (
      <div className="container">
        <div className="product-type-label">
          <p className="sub-heading ps-3">Commercial Products</p>
        </div>

        {products.length > 0 ? (
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col col-lg-4 d-none d-lg-block">
              <div id="product-list" className="product-list">
                <Accordion
                  defaultActiveKey={window.innerWidth > 767 ? "0" : ""}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      <p className="list-group-header">MATLAB / Simulink</p>
                    </Accordion.Header>
                    <Accordion.Body className="list-group-menu">
                      <ListGroup className="p-0" as="ul">
                        {products.length > 0 &&
                          products.map(
                            (product, index) =>
                              (product.productType === "matlab" ||
                                product.productType === "simulink") && (
                                <ListGroup.Item
                                  key={index}
                                  className={`list-group-product ${
                                    this.state.activeProduct === index
                                      ? "active-product"
                                      : ""
                                  }`}
                                  as="li"
                                >
                                  <Link
                                    className={product.title}
                                    to={product.title}
                                    offset={-50}
                                    // spy={true}
                                    onClick={() =>
                                      this.addActiveProductClass(index)
                                    }
                                  >
                                    {product.title}
                                  </Link>
                                </ListGroup.Item>
                              )
                          )}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion
                  defaultActiveKey={window.innerWidth > 767 ? "0" : ""}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <p className="list-group-header">Python</p>
                    </Accordion.Header>
                    <Accordion.Body className="list-group-menu">
                      <ListGroup className="p-0" as="ul">
                        {products.length > 0 &&
                          products.map(
                            (product, index) =>
                              product.productType === "python" && (
                                <ListGroup.Item
                                  key={index}
                                  className={`list-group-product ${
                                    this.state.activeProduct === index
                                      ? "active-product"
                                      : ""
                                  }`}
                                  as="li"
                                >
                                  <Link
                                    className={product.title}
                                    to={product.title}
                                    offset={-50}
                                    // spy={true}
                                    onClick={() =>
                                      this.addActiveProductClass(index)
                                    }
                                  >
                                    {product.title}
                                  </Link>
                                </ListGroup.Item>
                              )
                          )}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col col-lg-8">
              <div className="product-wrapper">
                {products.length > 0 &&
                  products.map((product, index) => (
                    <div
                      className="product-container"
                      key={index}
                      name={product.title}
                    >
                      <div className="row row-cols-1 row-cols-md-2">
                        <div className="col col-md-5 d-none d-md-block">
                          <img
                            src={product.thumbnail}
                            className="rounded img-fluid fixed-width"
                            alt="..."
                          />
                        </div>
                        <div className="col col-md-7">
                          <p className="sub-heading product-title">
                            {product.title}
                          </p>
                          <p className="contents">
                            {parse(product.shortDescription)}
                          </p>
                        </div>
                      </div>

                      {product.postContents && (
                        <div className="contents pt-3">
                          <Accordion
                            defaultActiveKey={
                              window.innerWidth > 767 ? "0" : ""
                            }
                          >
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                Detailed description
                              </Accordion.Header>
                              <Accordion.Body className="detailed-desc">
                                {product.postContents &&
                                  product.postContents.map((content, index) => (
                                    <div className="blog-cell" key={index}>
                                      {content.title && (
                                        <div className="blog-sub-heading">
                                          {content.title}
                                        </div>
                                      )}
                                      {content.content && (
                                        <div className="blog-contents">
                                          {parse(content.content)}
                                        </div>
                                      )}
                                      {content.image && (
                                        <img
                                          src={content.image}
                                          className="img-fluid"
                                          alt="..."
                                        ></img>
                                      )}
                                      {content.furtherMore && (
                                        <div className="blog-contents">
                                          {parse(content.furtherMore)}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      )}

                      <Collapse
                        in={this.state.collapseOpen.indexOf(product._id) !== -1}
                      >
                        <div className="form-wrapper free-products-form">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label required-field"
                            >
                              Full Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              value={account.name}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label required-field"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              value={account.email}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput3"
                              className="form-label required-field"
                            >
                              Purpose
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="purpose"
                              id="purpose"
                              value={account.purpose}
                              onChange={this.handleChange}
                            />
                          </div>
                          {this.state.error.length > 0 && (
                            <Alert variant="danger">{this.state.error}</Alert>
                          )}
                        </div>
                      </Collapse>

                      <div className="download-btn-wrapper">
                        <button
                          className="btn download-btn"
                          type="button"
                          onClick={() => this.setCollapse(product._id)}
                          disabled={this.state.sendingMail}
                        >
                          {this.state.collapseOpen.includes(product._id)
                            ? "Contact Us"
                            : "Buy Product"}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="loader">
            <TailSpin color="#0074c1" height={80} width={80} />
          </div>
        )}

        <div>
          <ToastContainer autoClose={5000} />
        </div>
        <hr className="contact-seperator" />
        <BackToTop />
      </div>
    );
  }
}

export default CommercialProducts;
