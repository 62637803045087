import http from "./httpService";

const commercialProductApiURL =
  process.env.REACT_APP_API_URL + "/api/commercial-products";

async function getCommercialProducts() {
  return http.get(commercialProductApiURL);
}

export { getCommercialProducts };
