import React from "react";
import ReactDOM from "react-dom";
const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

class Payment extends React.Component {
  createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [
        {
          amount: { value: this.props.amount },
        },
      ],
    });
  }
  onApprove = async (data, actions) => {
    const orders = await actions.order.capture();
    console.log(orders);
    this.props.onPayment(true);
  };
  onError(error) {
    console.log("error--", error);
    this.props.onPayment(false);
  }
  render() {
    return (
      <div className="payment-container">
        <div className="payment-wrapper">
          <PayPalButton
            createOrder={(data, actions) => this.createOrder(data, actions)}
            onApprove={(data, actions) => this.onApprove(data, actions)}
          />
        </div>
      </div>
    );
  }
}

export default Payment;
