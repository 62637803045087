import React from "react";

class TopContact extends React.Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-2 top-contact-wrapper">
          <div className="col text-center text-lg-end">
            <a className="contents" href="mailto:contactus@sysenso.com">
              contactus@sysenso.com
            </a>
          </div>
          <div className="col text-center text-lg-start">
            <a className="contents" href="tel:++91-9840724398">
              +91-9840724398 /
            </a>
            <a className="contents" href="tel:+91-44 48613398">
              +91-44 48613398
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default TopContact;
