import React, { Component } from "react";
import { Link } from "react-router-dom";
import notFound from "../Assets/Images/not-found.png";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="container not-found-wrapper">
          <div className="col">
            <h1>Error: 404</h1>
          </div>
          <div className="col">
            <img
              src={notFound}
              className="img-fluid thumb-img rounded w-50"
              alt="..."
            />
          </div>
          <div className="col fof">
            <p>The page you're looking for doesn't exist.</p>
          </div>
        </div>
      </>
    );
  }
}

export default NotFound;
