import React from "react";
import { getBlogList } from "../Services/blogService";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

class Blog extends React.Component {
  state = {
    blogs: [],
  };
  async componentDidMount() {
    const { data: blogs } = await getBlogList();
    this.setState({ blogs });
  }
  render() {
    const { blogs } = this.state;
    const recentPost = blogs.sort(
      (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
    );
    return (
      <div className="container ">
        <div className="main-content-wrapper">
          <p className="headings">Blog</p>
          <hr className="heading-seperator" />
          <p className="contents">
            Some of the automation ideas useful for the MATLAB/Simulink,
            Scilab/Xcos, Octave and Python developers. Also, it includes some
            tutorials with Embedded Systems, IoT, etc..
          </p>
          <div className="blog-list">
            {recentPost.length > 0 ? (
              recentPost.map((blog) => (
                <React.Fragment key={blog._id}>
                  <div className="row row-cols-1 row-cols-md-2">
                    <div className="col-11 col-sm-6 col-lg-4 col-xl-3 m-auto">
                      <img
                        src={blog.thumbnail}
                        className="img-thumbnail"
                        alt="..."
                      />
                    </div>
                    <div className="col col-sm-6 col-lg-8 col-xl-9 mt-5 mt-md-0">
                      <p className="contents">{blog.publishedDate}</p>
                      <p className="sub-heading">{blog.title}</p>
                      <p className="contents">{blog.shortDescription}</p>
                      <Link
                        to={`/blogs/${blog._id}`}
                        className="continue-reading-blog"
                      >
                        Continue Reading
                      </Link>
                    </div>
                  </div>

                  <hr className="blog-seperator" />
                </React.Fragment>
              ))
            ) : (
              <div className="loader">
                <TailSpin color="#0074c1" height={80} width={80} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
