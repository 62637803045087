import React from "react";
import { Events, Link } from "react-scroll";
import { Accordion, Collapse, Alert, ListGroup } from "react-bootstrap";
import {
  getFreeProducts,
  getReadmeContents,
} from "../Services/freeProductService";
import Joi from "joi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactMarkdown from "react-markdown";
import { addUser } from "../Services/userService";
import { TailSpin } from "react-loader-spinner";
import Donation from "../Components/donation";
import Spinner from "react-bootstrap/Spinner";
import BackToTop from "../Parts/backToTop";

class FreeProducts extends React.Component {
  state = {
    products: [],
    collapseOpen: [],
    activeProduct: 0,
    downloading: false,
    account: {
      name: "",
      email: "",
      purpose: "",
    },
    error: "",
  };

  async componentDidMount() {
    window.addEventListener("scroll", this.handleWindowScroll);

    const { data: products } = await getFreeProducts();

    if (products.length > 0) {
      await Promise.all(
        products.map(async (product) => {
          const result = await getReadmeContents(product.repo);
          if (result) {
            const splitResult = result.data.split("\n");
            // Short Description
            let index = 1;
            for (let item of splitResult.slice(index)) {
              if (item.length > 0) {
                product.shortDescription = item;
                index += 1;
                break;
              }
            }
            // Detailed Description
            product.description = splitResult.slice(index).join("\n");
          } else product.description = null;
        })
      );
    }

    this.setState({ products });

    Events.scrollEvent.register("begin", function () {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      // console.log("end", arguments);
    });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  schema = Joi.object({
    name: Joi.string().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    purpose: Joi.string().required().label("Purpose"),
  });

  // Validate on Submit
  validate = () => {
    const result = this.schema.validate(this.state.account);

    if (!result.error) return null;

    const errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  handleChange = ({ target: input }) => {
    const account = { ...this.state.account };
    account[input.name] = input.value;
    this.setState({ account });
  };

  downloadSoftware = async (productId) => {
    const productName = this.state.products.find(
      (product) => product._id === productId
    ).title;
    this.setState({ downloading: true });
    const { data: result } = await addUser({
      productType: "Free",
      productId: productId,
      productName: productName,
      ...this.state.account,
    });

    if ("ETag" in result) {
      try {
        const userName = "BharathiSysenso";
        //   const repoName = `${req.body.productName}`;
        const repoName = "ScilabAnimation";
        window.location.href = `https://${userName}:${process.env.GITHUB_TOKEN}@github.com/${userName}/${repoName}/archive/refs/heads/master.zip`;
        this.setState({ downloading: false });
        toast.success(`Download Success`);
      } catch (error) {
        toast.error("Download Failed");
      }
    }
  };

  setCollapse = (productIndex) => {
    const { collapseOpen } = this.state;
    const index = collapseOpen.indexOf(productIndex);

    if (index === -1) {
      collapseOpen.push(productIndex);
      this.setState({ collapseOpen });
    } else {
      const error = this.validate();
      if (error) {
        const key = Object.keys(error)[0];
        this.setState({ error: error[key] });
      } else {
        this.setState({ error: "" });
        this.downloadSoftware(productIndex);
      }
    }
  };

  addActiveProductClass = (productIndex) => {
    this.setState({ activeProduct: productIndex });
  };

  handleWindowScroll = () => {
    let element = document.getElementById("product-list");
    if (element) {
      let heightDiff = window.innerHeight - element.offsetHeight;
      if (heightDiff < 0) {
        element.style.position = "sticky";
        element.style.top = heightDiff - 5 + "px";
      } else {
        element.style.position = "sticky";
        element.style.top = "50px";
      }
    }
  };

  render() {
    const { products, account } = this.state;
    return (
      <div className="container">
        <div className="product-type-label">
          <p className="sub-heading ps-2">Free Products</p>
        </div>
        {products.length > 0 ? (
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col col-lg-4 d-none d-lg-block">
              <div id="product-list" className="product-list">
                <Accordion
                  defaultActiveKey={window.innerWidth > 767 ? "0" : ""}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      <p className="list-group-header">MATLAB / Simulink</p>
                    </Accordion.Header>
                    <Accordion.Body className="list-group-menu">
                      <ListGroup className="p-0" as="ul">
                        {products.length > 0 &&
                          products.map(
                            (product, index) =>
                              (product.productType === "matlab" ||
                                product.productType === "simulink") && (
                                <ListGroup.Item
                                  key={index}
                                  className={`list-group-product ${
                                    this.state.activeProduct === index
                                      ? "active-product"
                                      : ""
                                  }`}
                                  as="li"
                                >
                                  <Link
                                    className={product.title}
                                    to={product.title}
                                    offset={-50}
                                    // spy={true}
                                    onClick={() =>
                                      this.addActiveProductClass(index)
                                    }
                                  >
                                    {product.title}
                                  </Link>
                                </ListGroup.Item>
                              )
                          )}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion
                  defaultActiveKey={window.innerWidth > 767 ? "0" : ""}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <p className="list-group-header">Scilab / Xcos</p>
                    </Accordion.Header>
                    <Accordion.Body className="list-group-menu">
                      <ListGroup className="p-0" as="ul">
                        {products.length > 0 &&
                          products.map(
                            (product, index) =>
                              product.productType === "python" && (
                                <ListGroup.Item
                                  key={index}
                                  className={`list-group-product ${
                                    this.state.activeProduct === index
                                      ? "active-product"
                                      : ""
                                  }`}
                                  as="li"
                                >
                                  <Link
                                    className={product.title}
                                    to={product.title}
                                    offset={-50}
                                    // spy={true}
                                    onClick={() =>
                                      this.addActiveProductClass(index)
                                    }
                                  >
                                    {product.title}
                                  </Link>
                                </ListGroup.Item>
                              )
                          )}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col col-lg-8">
              <div className="product-wrapper">
                {products.length > 0 &&
                  products.map((product, index) => (
                    <div
                      className="product-container"
                      key={index}
                      name={product.title}
                    >
                      <div className="row row-cols-1 row-cols-md-2">
                        <div className="col col-md-5 d-none d-md-block">
                          <img
                            src={product.thumbnail}
                            className="rounded img-fluid fixed-width"
                            alt="..."
                          />
                        </div>
                        <div className="col col-md-7">
                          <p className="sub-heading product-title">
                            {product.title}
                          </p>
                          <p className="contents">{product.shortDescription}</p>
                        </div>
                      </div>

                      {product.description && (
                        <div className="contents pt-3">
                          <Accordion
                            defaultActiveKey={
                              window.innerWidth > 767 ? "0" : ""
                            }
                          >
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                Detailed description
                              </Accordion.Header>
                              <Accordion.Body className="detailed-desc">
                                {
                                  <ReactMarkdown
                                    components={{ h2: "h5" }}
                                    className="readme-contents"
                                  >
                                    {product.description}
                                  </ReactMarkdown>
                                }
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      )}

                      <Collapse
                        in={this.state.collapseOpen.indexOf(product._id) !== -1}
                      >
                        <div className="form-wrapper free-products-form">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label required-field"
                            >
                              Full Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              value={account.name}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label required-field"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              value={account.email}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput3"
                              className="form-label required-field"
                            >
                              Purpose
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="purpose"
                              id="purpose"
                              value={account.purpose}
                              onChange={this.handleChange}
                            />
                          </div>
                          {this.state.error.length > 0 && (
                            <Alert variant="danger">{this.state.error}</Alert>
                          )}
                          <Donation />
                        </div>
                      </Collapse>

                      <div className="download-btn-wrapper">
                        {this.state.downloading ? (
                          <button className="btn download-btn" type="button">
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp; Downloading...
                          </button>
                        ) : (
                          <button
                            className="btn download-btn"
                            type="button"
                            onClick={() => this.setCollapse(product._id)}
                          >
                            Download Software
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="loader">
            <TailSpin color="#0074c1" height={80} width={80} />
          </div>
        )}

        <div>
          <ToastContainer autoClose={5000} />
        </div>
        <hr className="contact-seperator" />
        <BackToTop />
      </div>
    );
  }
}

export default FreeProducts;
