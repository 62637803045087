import React from "react";
import youtube from "../Assets/Images/youtube.png";
import linkedin from "../Assets/Images/linkedin.png";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="row social-network">
          <div className="col">
            {" "}
            <a
              href="https://www.linkedin.com/company/sysenso/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src={linkedin}
                className="img-fluid rounded float-end"
                alt="Responsive"
              ></img>
            </a>
          </div>
          <div className="col">
            {" "}
            <a
              href="https://www.youtube.com/channel/UCt7M4Ek0sXkEdA994XlqZpA?view_as=subscriber"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={youtube}
                className="img-fluid rounded float-start"
                alt="Responsive"
              ></img>
            </a>
          </div>
        </div>
        <div className="copy-right">
          <p className="text-center">
            Copyright © 2021 Sysenso - All Rights Reserved. |
          </p>
        </div>
        <div className="other-license">
          <p className="text-center">
            MATLAB® is a registered trademark of The MathWorks, Inc.. | Octave
            is released under open source GPL license| Scilab is governed by the
            CeCILL license (GPL compatible){" "}
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
