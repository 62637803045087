import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { getBlogList, getBlogs } from "../Services/blogService";
import { TailSpin } from "react-loader-spinner";

class BlogDetails extends React.Component {
  state = {
    blog: {},
    blogList: [],
    currentURL: this.props.match.params.id,
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    const { data: blog } = await getBlogs(this.props.match.params.id);
    const { data: blogList } = await getBlogList();
    this.setState({ blog, blogList });
  }

  async componentDidUpdate(prevState) {
    if (prevState.match.params.id !== this.props.match.params.id) {
      window.scrollTo(0, 0);
      const { data: blog } = await getBlogs(this.props.match.params.id);
      const { data: blogList } = await getBlogList();
      this.setState({ blog, blogList });
    }
  }

  handleChangeBlog = () => {
    const currentURL = this.props.match.params.id;
    this.setState({ currentURL });
  };

  render() {
    const { blog } = this.state;

    const recentPost = this.state.blogList
      .sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate))
      .filter((post) => post._id !== this.props.match.params.id)
      .slice(0, 3);

    return (
      <div className="container">
        <div className="main-content-wrapper">
          <p className="headings">Blog</p>
          <hr className="heading-seperator" />
          <p className="contents">
            Some of the automation ideas useful for the MATLAB/Simulink,
            Scilab/Xcos, Octave and Python developers. Also, it includes some
            tutorials with Embedded Systems, IoT, etc..
          </p>
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col col-lg-8 blog-post-wrapper">
              {Object.keys(blog).length > 0 ? (
                <div className="blog-post">
                  <Link to={`/blogs`} className="all-post">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                    &nbsp; All Post
                  </Link>
                  <p className="blog-heading">{blog.title}</p>
                  <p className="blog-contents">{blog.publishedDate}</p>
                  {blog.showThumbInBlog && (
                    <img
                      src={blog.thumbnail}
                      className="img-fluid"
                      alt="..."
                    ></img>
                  )}
                  <p className="blog-contents">{blog.shortDescription}</p>
                  {blog.postContents.map((content, index) => (
                    <div className="blog-cell" key={index}>
                      {content.title && (
                        <div className="blog-sub-heading">{content.title}</div>
                      )}
                      {content.content && (
                        <div className="blog-contents">
                          {parse(content.content)}
                        </div>
                      )}
                      {content.image && (
                        <img
                          src={content.image}
                          className="img-fluid"
                          alt="..."
                        ></img>
                      )}
                      {content.furtherMore && (
                        <div className="blog-contents">
                          {parse(content.furtherMore)}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="loader">
                  <TailSpin color="#0074c1" height={80} width={80} />
                </div>
              )}

              {Object.keys(blog).length > 0 && (
                <hr className="contact-seperator d-lg-none" />
              )}
            </div>

            <div className="col col-lg-4 ">
              <div className="main-content-wrapper">
                {recentPost.length > 0 && (
                  <p className="sub-heading">Recent Posts</p>
                )}
                {recentPost.length > 0 &&
                  recentPost.map((post, index) => (
                    <Link
                      to={`/blogs/${post._id}`}
                      key={index}
                      className="recent-post m-2"
                      onClick={this.handleChangeBlog}
                    >
                      <div className="row">
                        <div className="col-5">
                          <img
                            src={post.thumbnail}
                            className="img-thumbnail"
                            alt="..."
                          />
                        </div>
                        <div className="col-7">
                          <p className="recent-post-heading">{post.title}</p>
                          <p className="recent-post-heading">
                            {post.publishedDate}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          {Object.keys(blog).length > 0 && <hr className="contact-seperator" />}
        </div>
      </div>
    );
  }
}

export default BlogDetails;
