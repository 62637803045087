import React from "react";

class Tools extends React.Component {
  state = {};
  render() {
    return (
      <div className="container">
        <h1>Tools</h1>
      </div>
    );
  }
}

export default Tools;
