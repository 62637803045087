import React from "react";
import logo from "../Assets/Images/logo.jpg";

class Header extends React.Component {
  render() {
    return (
      <div className="header-wrapper">
        <div className="logo">
          <img
            src={logo}
            className="img-fluid rounded mx-auto d-block"
            alt="Responsive"
          ></img>
        </div>
        <div className="organization-name">
          <p className="text-center text-uppercase">
            sysenso systems private limited
          </p>
        </div>
      </div>
    );
  }
}

export default Header;
