import "./App.css";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import TopContact from "./Parts/topContact";
import NavBar from "./Parts/navbar";
import Header from "./Parts/header";
import Footer from "./Parts/footer";
import Home from "./Pages/home";
import AboutUs from "./Pages/aboutUs";
import Services from "./Pages/services";
import FreeProducts from "./Pages/freeProducts";
import CommercialProducts from "./Pages/commercialProducts";
import Tools from "./Pages/tools";
import Blog from "./Pages/blog";
import BlogDetails from "./Components/blogDetails";
import ContactUs from "./Pages/contactUs";
import NotFound from "./Components/notFound";

function App() {
  return (
    <React.Fragment>
      {/* <TopContact /> */}
      <NavBar />
      <Header />
      <Switch>
        <Route path={`/`} exact component={Home} />
        <Route path={`/about-us`} component={AboutUs} />
        <Route path={`/services`} component={Services} />
        <Route path={`/tools`} component={Tools} />
        <Route path={`/free-products`} component={FreeProducts} />
        <Route path={`/commercial-products`} component={CommercialProducts} />
        <Route path={`/blogs/:id`} component={BlogDetails} />
        <Route path={`/blogs`} component={Blog} />
        <Route path={`/contact-us`} component={ContactUs} />
        <Redirect from={`/home`} exact to="/" />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
}

export default App;
