import React from "react";
import { sendMail } from "../Services/emailService";

class ContactUs extends React.Component {
  state = {
    user: {
      name: "",
      email: "",
      message: "",
    },
    messageSent: false,
  };

  handleSubmit = async () => {
    const { data: result } = await sendMail(this.state.user);
    if ("MessageId" in result || "ResponseMetadata" in result) {
      this.setState({ messageSent: true });
    }
  };

  handleChange = ({ target: input }) => {
    const user = { ...this.state.user };
    user[input.name] = input.value;
    this.setState({ user });
  };

  render() {
    const { user } = this.state;
    return (
      <div className="container">
        <div className="main-content-wrapper">
          <p className="headings">Contact Us</p>
          <hr className="heading-seperator" />
          <div className="row row-cols-1 row-cols-xl-2">
            <div className="col">
              <div className="contact-us-form">
                <p className="sub-heading text-center">Drop us a line!</p>
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    value={user.name}
                    className="form-control input-height"
                    id="name"
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    value={user.email}
                    className="form-control input-height"
                    id="email"
                    placeholder="Email"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    name="message"
                    value={user.message}
                    id="message"
                    rows="5"
                    placeholder="Message"
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="d-grid gap-2">
                  <button
                    className="btn send-btn"
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    send
                  </button>
                </div>
              </div>
              {this.state.messageSent && (
                <div className="thank-you-wrapper">
                  <div className="alert alert-info" role="alert">
                    Thank You For Contacting Us
                  </div>
                </div>
              )}
            </div>
            <div className="col">
              <div className="contact-us-contents">
                <p className="sub-heading">Better yet, see us in person!</p>
                <p className="contents pt-3">
                  Please write to us if you have any queries about our tools and
                  services offered by us.
                </p>
                <p className="sub-heading">Sysenso Systems Private Limited</p>
                <p className="contents pt-3">
                  Plot # 31, Door # 2/543, Ground Floor, Kumaran Street, Balaiah
                  Garden, Madipakkam, Chennai, Tamil Nadu – 600 091, INDIA
                </p>
                <p className="contents m-0">
                  Phone: <a href="tel:++91-9840724398">+91-9840724398</a> /{" "}
                  <a href="tel:+91-44 48613398">+91-44 48613398</a>
                </p>
                <p className="contents m-0">
                  Email:{" "}
                  <a href="mailto:contactus@sysenso.com">
                    contactus@sysenso.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="contact-seperator" />
      </div>
    );
  }
}

export default ContactUs;
