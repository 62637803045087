import http from "./httpService";

const mailApiURL = process.env.REACT_APP_API_URL + "/api/send-mail";

function sendMail(content) {
  return http.post(mailApiURL, content);
}

function contactBuyProduct(content) {
  return http.post(mailApiURL + "/contact-buy-product", content);
}

export { sendMail, contactBuyProduct };
