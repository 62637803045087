import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav, NavDropdown } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

class NavBar extends React.Component {
  state = {
    activeTab: window.location.pathname,
  };

  handleTabClick = (activeTab) => {
    this.setState({ activeTab });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <Navbar bg="light" expand="lg" sticky="top">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto nav-items">
              <Link
                to={`/home`}
                className={activeTab === "/" ? "active-tab" : ""}
                onClick={() => this.handleTabClick("/")}
              >
                Home
              </Link>
              <Link
                to={`/about-us`}
                className={activeTab === `/about-us` ? "active-tab" : ""}
                onClick={() => this.handleTabClick(`/about-us`)}
              >
                About Us
              </Link>
              <Link
                to={`/services`}
                className={activeTab === `/services` ? "active-tab" : ""}
                onClick={() => this.handleTabClick(`/services`)}
              >
                Services
              </Link>

              <NavDropdown title="Products" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to={`/free-products`}
                  className={activeTab === `/free-products` ? "active-tab" : ""}
                  onClick={() => this.handleTabClick(`/free-products`)}
                >
                  Free Products
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={`/commercial-products`}
                  className={
                    activeTab === `/commercial-products` ? "active-tab" : ""
                  }
                  onClick={() => this.handleTabClick(`/commercial-products`)}
                >
                  Commercial Products
                </NavDropdown.Item>
              </NavDropdown>

              <Link
                to={`/blogs`}
                className={activeTab === `/blogs` ? "active-tab" : ""}
                onClick={() => this.handleTabClick(`/blogs`)}
              >
                Blog
              </Link>
              <Link
                to={`/contact-us`}
                className={activeTab === `/contact-us` ? "active-tab" : ""}
                onClick={() => this.handleTabClick(`/contact-us`)}
              >
                Contact Us
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavBar;
