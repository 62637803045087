import http from "./httpService";

const freeProductApiURL = process.env.REACT_APP_API_URL + "/api/free-products";

async function getFreeProducts() {
  return http.get(freeProductApiURL);
}

async function getReadmeContents(repo) {
  try {
    const response = await http.get(
      `https://raw.githubusercontent.com/SysensoSystems/${repo}/main/README.md`
    );
    return response;
  } catch (error) {
    return null;
  }
}

export { getFreeProducts, getReadmeContents };
