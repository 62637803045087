import React from "react";

class Contact extends React.Component {
  render() {
    return (
      <div className="container contact-wrapper">
        <p className="headings text-center">contact us</p>
        <hr className="heading-seperator" />
        <div className="p-3 text-center">
          <p className="sub-heading p-3">Office Address</p>
          <p className="sub-heading p-2">Sysenso Systems Private Limited</p>
          <p className="contents p-2">
            Plot # 31, Door # 2/543, Ground Floor, Kumaran Street, Balaiah
            Garden, Madipakkam, Chennai, Tamil Nadu – 600 091, INDIA
          </p>
          <p className="contents m-0">
            Phone: <a href="tel:++91-9840724398">+91-9840724398</a> /{" "}
            <a href="tel:+91-44 48613398">+91-44 48613398</a>
          </p>
          <p className="contents m-0">
            Email:{" "}
            <a href="mailto:contactus@sysenso.com">contactus@sysenso.com</a>
          </p>
        </div>
        <hr className="contact-seperator" />
      </div>
    );
  }
}

export default Contact;
